<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <div v-if="$apollo.loading">Loading...</div>
          <div v-else>
            <b-table id="alert" responsive :items="this.alerts.edges" :fields="fields">
              <template v-slot:cell(index)="data">
                <td>{{ (data.index+1)+((currentPage-1)*perPage) }}</td>
              </template>
              <template v-slot:cell(code)="data">
                <td>{{ data.item.node.code }}</td>
              </template>
              <template v-slot:cell(content)="data">
                <td>{{ data.item.node.content }}</td>
              </template>
              <template v-slot:cell(createdAt)="data">
                <td>{{ formatDateTime(data.item.node.createdAt) }}</td>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="alerts.totalCount"
              :per-page="perPage"
              aria-controls="alert"
              align="center"
            ></b-pagination>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// -- Module --
import moment from "moment";

// -- Query --
import { ALERT_QUERY } from "../../components/alert-query";
import { ROBOT_QUERY } from "../../components/job-query";

// -- const variable --
// TODO: ログインユーザから表示させるロボットIDを取得するようにする。今は固定値。
const ROBOT_ID_INDEX = 0;

export default {
  components: {},
  data() {
    return {
      alerts: {},
      robotId: null,
      perPage: 30, //1ページ30件ごとに表示
      currentPage: 1, //初期ページ番号
      fields: [
        { key: "index", label: "index" },
        { key: "code", label: "エラーコード" },
        { key: "content", label: "エラー内容" },
        { key: "createdAt", label: "作成日時" }
      ]
    };
  },
  watch: {
    // --------------------------------
    // ページ移動したら再取得
    // --------------------------------
    currentPage: function() {
      if (!Object.keys(this.alerts).length) {
        return;
      }

      let pageNum;
      if (this.currentPage == this.maxPage()) {
        pageNum = this.pageCalc();
      } else {
        pageNum = this.perPage * this.currentPage;
      }

      this.$apollo.queries.alerts.refetch({
        first: this.perPage * this.currentPage,
        last: pageNum,
        robotId: this.robotId
      });
    }
  },
  methods: {
    maxPage: function() {
      return Math.ceil(this.alerts.totalCount / this.perPage);
    },
    pageCalc: function() {
      let pegeNum = this.alerts.totalCount % this.perPage;
      if (this.maxPage() == this.currentPage && pegeNum) {
        return pegeNum;
      } else {
        return this.perPage;
      }
    },
    formatDateTime: function(dateTime) {
      return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    }
  },
  apollo: {
    // 今はロボットIDを全件取得して、その中から対象のロボットIDを定数で指定して取得
    // TODO: 何かしらの引数で対象ロボットを指定してロボットIDを取得
    robots: {
      query: ROBOT_QUERY,
      result({ data }) {
        this.robotId = data.robots.edges[ROBOT_ID_INDEX].node.id;
        this.$apollo.addSmartQuery("alerts", {
          query: ALERT_QUERY,
          variables: {
            first: this.perPage,
            last: this.perPage,
            robotId: this.robotId
          }
        });
      }
    }
  }
};
</script>
