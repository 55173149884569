<template>
  <div>
    <robot-alert></robot-alert>
  </div>
</template>

<script>
import RobotAlert from "../components/RobotAlert";

export default {
  components: {
    RobotAlert
  },
  data() {
    return {};
  }
};
</script>
