import gql from "graphql-tag";

export const ALERT_QUERY = gql`
  query($first:Int,$last:Int$robotId: ID) {
    alerts(first:$first,last:$last,robotId: $robotId,orderBy: "-created_at"){
      totalCount
      edges{
        node{
          id
          content
          code
          createdAt
        }
      }
    }
  }
`;
